import React from "react";
import Navigation from "../components/mainPage/Navigation";
import Footer from "../components/mainPage/Footer";
import ProductsList from "../components/productsPage/ProductsList";

function ProductsPage() {
  return (
    <div>
      <Navigation />
      <ProductsList />
      <Footer />
    </div>
  );
}

export default ProductsPage;
