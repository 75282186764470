import React from "react";
import { Link as OuterLink } from "react-router-dom";

import "./OneProduct.css";

function ProductPresentationCard(props) {
  return (
    <div className="w-full h-fit flex bg-[#DEDEDE] flex-col xl:flex-row">
      <div className="flex flex-col xl:flex-row max-w-[1200px] mx-auto">
        {/*Left side*/}
        <div className="w-full p-10 flex">
          <div className="xl:w-[80%]  h-[80vh] mx-auto border-[1px] border-slate-500 bg-white p-10 flex">
            <img className="m-auto max-h-full w-auto" src={props.image} alt="" />
          </div>
        </div>
        {/*Right side*/}
        <div className="w-full flex py-5">
          <div className="w-[80%] mx-auto">
            <h1 className="text-5xl py-5 font-thin">{props.title}</h1>
            <div className="xl:w-[80%]">
              <ul className="tracking-widest text-slate-600">
                {props.description.map((point, index) => (
                  <li key={index} className="my-3 flex"><p className="text-[#4B89CA] px-1">• </p>{point}</li>
                ))}
              </ul>
            </div>
            <div>
              <div className="flex flex-col justify-between w-[40%] mt-5 py-3">
                <OuterLink to="/kontakt">
                  <button
                    className="border border-[#4B89CA] p-3 px-10 bg-[#4B89CA] text-white  shadow-black
        font-bold hover:bg-transparent hover:text-[#4B89CA] flex text-lg button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105 w-[230px] text-center"
                  >
                    <p className="w-fit mx-auto">Zamów produkt!</p>
                  </button>
                </OuterLink>
                <OuterLink to={`/produkty/${props.type}`}>
                  <button
                    className="border border-[#4B89CA] text-[#4B89CA] p-3 px-10 bg-transparent shadow-black
        font-bold hover:text-black hover:border-none hover:bg-[#f5fbf9] flex text-lg button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105 w-[230px] mt-5"
                  >
                    <p className="w-fit mx-auto">Sprawdź inne!</p>
                  </button>
                </OuterLink>
              </div>
            </div>
            <div className="w-[80%] py-3">
              <p className="text-sm">Numery identyfikacyjne: {props.prodIds}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPresentationCard;
