import React, { useEffect, useState } from "react";
import { Link as OuterLink } from "react-router-dom";

import Navigation from "../mainPage/Navigation";
import Footer from "../mainPage/Footer";
import ProductCard from "./ProductCard";

function AllProductsPage() {
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all"); // Initialize with 'all' to show all products

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const [query, setQuery] = useState(""); //search bar for products

  const handleFilterChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const getData = () => {
    fetch("../products.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myjson) => {
        setProducts(myjson);
      });
  };

  const getFilters = () => {
    const groups = {}; // Create an object to store one item per group

    // Iterate through the data and select the first item from each group
    products.forEach((item) => {
      if (!groups[item.type]) {
        groups[item.type] = item; // Store the first item of each group
      }
    });

    // Convert the object of one item per group back to an array
    const result = Object.values(groups);

    return result;
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setFilters(getFilters());
    console.log(filters);
  }, [products]);

  const filteredProducts =
    selectedCategory === "all"
      ? products
      : products.filter((product) => product.type === selectedCategory);

  return (
    <div>
      <Navigation />
      <div className="w-full bg-[#DEDEDE] flex pb-10">
        {/* left side */}
        {/* <div className="flex flex-col w-1/5 mt-20">
          <div className="w-fit mx-auto flex flex-col p-3">
            <h3 className="text-3xl pb-5">Filtry</h3>
            <label className="py-2 flex">
              <input
                className="mr-2"
                type="radio"
                name="category"
                value="all"
                checked={selectedCategory === "all"}
                onChange={handleCategoryChange}
              />
              <p className="text-xl">Wszystko</p>
            </label>
            {filters &&
              filters.length > 0 &&
              filters.map((product) => {
                return (
                  <label className="py-2 flex border-b-[1px] border-gray-400">
                    <input
                      className="mr-2"
                      type="radio"
                      name="category"
                      value={product.type}
                      checked={selectedCategory === product.type}
                      onChange={handleCategoryChange}
                    />
                    <p className="text-xl">{product.groupTitle}</p>
                  </label>
                );
              })}
          </div>
        </div> */}
        {/* right side */}
        <div className="w-[90%] mx-auto flex h-fit">
          <div className="w-full flex flex-col">
            <h1 className="text-5xl py-10 font-thin ml-4">
              Wszystkie produkty
            </h1>
            <input
              className="w-[300px] p-3 rounded-full font-light ml-4"
              placeholder="Szukaj..."
              type="text"
              id="fname"
              name="fname"
              onChange={(e) => setQuery(e.target.value)}
            />
            <div className="flex flex-col mt-3">
              <div className="w-fit mx-auto flex flex-wrap p-3 font-light">
                <label className="flex h-fit items-center mr-2">
                  <input
                    className="mr-2"
                    type="radio"
                    name="category"
                    value="all"
                    checked={selectedCategory === "all"}
                    onChange={handleCategoryChange}
                  />
                  <p className="text-sm">Wszystko</p>
                </label>
                {filters &&
                  filters.length > 0 &&
                  filters.map((product) => {
                    return (
                      <label className="flex h-fit items-center mr-2">
                        <input
                          className="mr-2"
                          type="radio"
                          name="category"
                          value={product.type}
                          checked={selectedCategory === product.type}
                          onChange={handleCategoryChange}
                        />
                        <p className="text-sm">{product.groupTitle}</p>
                      </label>
                    );
                  })}
              </div>
            </div>
            <div className="h-fit flex flex-wrap flex-row">
              {filteredProducts &&
                filteredProducts.length > 0 &&
                filteredProducts
                  .filter((product) =>
                    product.name.toLowerCase().includes(query.toLowerCase()) || product.type.toLowerCase().includes(query.toLowerCase())
                  )
                  .map((product) => {
                    return (
                      <OuterLink to={`/produkty/${product.type}/${product.id}`}>
                        <ProductCard
                          key={product.id}
                          name={product.name}
                          image={product.image}
                          shortDesc={product.description}
                          price={product.price}
                        />
                      </OuterLink>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllProductsPage;
