import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link as OuterLink } from "react-router-dom";

import ProductCard from "./ProductCard";

import szelki from "../../assets/szelki.png";

function ProductsList() {
  const { type } = useParams(); // Retrieve the 'id' parameter from the URL
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState(""); //search bar for products

  const getData = () => {
    fetch("../products.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((myjson) => {
        setProducts(myjson);
      });
  };

  useEffect(() => {
    getData();
  }, []); // Fetch data when the component mounts
  return (
    <div className="w-full bg-[#DEDEDE] flex pb-10">
      <div className="w-[90%] mx-auto flex h-fit">
        <div className="w-full flex flex-col">
          <h1 className="text-5xl py-10 font-thin ml-4">
            {products && products.length > 0 && (
              <p>
                {products.find((product) => product.type === type)?.groupTitle}
              </p>
            )}
          </h1>
          <input
            className="w-[300px] p-3 rounded-full font-light ml-4"
            placeholder="Szukaj..."
            type="text"
            id="fname"
            name="fname"
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="h-fit flex flex-wrap flex-row">
            {products &&
              products.length > 0 &&
              products
                .filter((product) =>
                  product.name.toLowerCase().includes(query.toLowerCase())
                )
                .map((product) => {
                  if (product.type === type) {
                    return (
                      <OuterLink to={`/produkty/${product.type}/${product.id}`}>
                        <ProductCard
                          key={product.id}
                          name={product.name}
                          image={product.image}
                          shortDesc={product.description}
                          price={product.price}
                        />
                      </OuterLink>
                    );
                  } else {
                    return null;
                  }
                })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsList;
