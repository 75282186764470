import React from "react";
import { Link as OuterLink } from "react-router-dom";

import szelki from "../../assets/szelki.png";

function Offer() {
  return (
    <div name="offer" className="w-full h-fit flex bg-[#DEDEDE] pb-10">
      <div className="w-full lg:w-[80%] h-fit flex flex-col mx-auto text-center">
        <div className="w-fit mx-auto p-7">
          <h1 className="text-5xl p-2">NASZA OFERTA</h1>
          <span class="block max-w-[15%] h-0.5 bg-[#4B89CA] mx-auto"></span>
        </div>
        <div className=" h-fit flex flex-wrap flex-row mx-auto justify-around mt-5">
          <OuterLink to="/produkty/szelki">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="">
                <img
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm_Detail_Auffanggurt_b2031c3262.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Uprzęże bezpieczeństwa
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/liny-łączące">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="">
                <img
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm_Detail_Verbindungsmittel_6367c21e0a.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Liny łączące
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/liny-pozycjonujące">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="">
                <img
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm__MG_0406-web_48501c97db.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Liny pozycjonujące
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/liny-bezpieczeństwa">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="">
                <img
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm__MG_0413-web_9fd5bfb134.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Liny bezpieczeństwa
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/amortyzatory-upadku">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="">
                <img
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm__MG_0419-web_2e741b9bc2.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Amortyzatory upadku
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/urządzenia-kotwiczące">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="">
                <img
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm_Detail_Anschlagpunkt_395e182a3a.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Urządzenia kotwiczące
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/statywy">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="h-[185px]">
                <img
                  className="h-full w-full"
                  src="https://www.ropemarine.com/image/cache/catalog/Products/safety-tripod-with-winch-900x720.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Statywy trójnogie
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/urządzenia-ratownicze">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="h-[185px]">
                <img
                  className="h-full w-full"
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm_Detail_Geraete2_3f05e22b31.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Urządzenia ratownicze
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/akcesoria">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="h-[185px]">
                <img
                  className="h-full w-full"
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm_Detail_Zubehoer_2387c8c7bf.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Akcesoria
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/multiaccess">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="h-[185px]">
                <img
                  className="h-full w-full"
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm_Detail_MultiAccess_84071968c1.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                MultiAccess
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/blokery-upadku">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="h-[185px]">
                <img
                  className="h-full w-full"
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm__MG_0398-web_94b26e546b.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Blokery upadku
              </h4>
            </div>
          </OuterLink>
          <OuterLink to="/produkty/systemy-horyzontalne">
            <div className="flex flex-col text-center w-[300px] p-3 bg-[#E8E8E8] mt-5 hover:bg-[#FCDB4B] hover:cursor-pointer">
              <div className="h-[185px]">
                <img
                  className="h-full w-full"
                  src="https://www.mittelmann.com/fileadmin/_processed_/csm_Detail_Uniline_65801e050b.jpg"
                  alt="Szelki bezpieczeństwa"
                />
              </div>
              <h4 className="text-xl font-light tracking-wider p-3 ">
                Systemy horyzontalne
              </h4>
            </div>
          </OuterLink>
        </div>
      </div>
    </div>
  );
}

export default Offer;
