import React from "react";
import ProductPresentation from "../components/oneProductPage/ProductPresentation";
import Navigation from "../components/mainPage/Navigation";
import Footer from "../components/mainPage/Footer";

function OneProductPage() {
  return (
    <div>
      <Navigation />
      <ProductPresentation />
      <Footer />
    </div>
  );
}

export default OneProductPage;
