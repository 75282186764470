import React from 'react'

function ProductCard(props) {
  return (
    <div className="flex flex-col text-center w-[270px] mx-4 p-3 bg-[#FDFDFD] mt-5 leading-6">
            <div className="mx-auto w-full h-[380px] flex">
              <img className=" max-h-[400px] mx-auto my-auto" src={props.image} alt="Szelki bezpieczeństwa" />
            </div>
            <h4 className="text-2xl mt-3 font-light tracking-wider p-3 leading-9">
              {props.name}
            </h4>
            <span className="h-[1px] w-[15%] bg-[#4B89CA] mx-auto"></span>
            {/* <p className='text-gray-500 h-[7.5rem]'>{props.shortDesc}</p> */}
            <div className='flex flex-row w-full justify-around p-3 text-xl font-light'>
                {/* <p>{props.price} zł</p>
                <p className='text-gray-400 line-through'>299.99 zł</p> */}
            </div>
            <div>
              <button className="px-8 py-3 bg-[#4B89CA] text-white rounded-full my-2 text-xl hover:bg-[#FCDB4B] hover:text-black hover:cursor-pointer">Kup teraz!</button>
            </div>
          </div>
  )
}

export default ProductCard