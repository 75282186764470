import React from "react";

import "./Advantages.css";

import safety from "../../assets/OfertaIkony/safetyIcon.png";
import clock from "../../assets/OfertaIkony/clock.png";
import piggy from "../../assets/OfertaIkony/piggy-bank.png";

function Advantages() {
  return (
    <div className="w-full h-fit flex advantages-bg">
      <div className="prio w-full xl:w-[80%] mx-auto p-5 h-fit flex flex-col xl:flex-row flex-wrap">
        <div className="flex flex-row w-[100%] xl:w-1/3">
          <div className="h-[150px] w-[500px]">
            <img
              className="h-full w-auto"
              src={safety}
              alt="Ikona bezpieczeństwa"
            />
          </div>
          <div>
            <h2 className="text-white text-xl md:text-3xl p-2">Bezpieczeństwo</h2>
            <p className='text-white p-2 text-sm md:text-md'>
              Nasze produkty to pewne i atestowane bezpieczeństwo. Życie i
              zdrowie jest najcienniejsze - dbaj o nie z nami!
            </p>
          </div>
        </div>
        <div className="flex flex-row w-[100%] xl:w-1/3">
          <div className="h-[150px] w-[500px]">
            <img
              className="h-full w-auto"
              src={clock}
              alt="Ikona zegara"
            />
          </div>
          <div>
            <h2 className="text-white text-xl md:text-3xl p-2">Szybkość</h2>
            <p className='text-white p-2 text-sm md:text-md'>
              Sprawnie zaopatrujemy naszych klientów co sprawia, że czas dostawy nie będzie opóźniał postępu prac.
            </p>
          </div>
        </div>
        <div className="flex flex-row w-[100%] xl:w-1/3">
          <div className="h-[150px] w-[500px]">
            <img
              className="h-full w-auto"
              src={piggy}
              alt="Ikona bezpieczeństwa"
            />
          </div>
          <div>
            <h2 className="text-white text-xl md:text-3xl p-2">Oszczędność</h2>
            <p className='text-white p-2 text-sm md:text-md'>
              Konkurencyjne ceny idące w parze ze świetną jakością powoduje, że jesteśmy najlepszym wyborem w branży.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advantages;
