import React from 'react'

import './Map.css'
function Map() {
  return (
    <div className='map-responsive'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.230638958707!2d19.514741176635507!3d51.765390671873135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471bcb575f5e8baf%3A0x5a6b4ff6662d507a!2zV2lkemV3c2thIDE0LCA5Mi0yMzAgxYHDs2TFug!5e0!3m2!1spl!2spl!4v1694181200385!5m2!1spl!2spl" width="1440" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map"></iframe>
    </div>
  )
}

export default Map
