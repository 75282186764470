import React from 'react'

function FeaturedItem(props) {
  return (
    <div className="flex flex-col text-center w-[270px] p-3 bg-[#FDFDFD] mt-5 hover:cursor-pointer">
            <div className="mx-auto w-full h-[380px] flex">
              <img className=" max-h-[400px] mx-auto my-auto" src={props.image} alt="Szelki bezpieczeństwa" />
            </div>
            <h4 className="text-2xl font-light tracking-wider pt-10">
              {props.title}
            </h4>
            {/* <p className='text-gray-500'>Jakiś krótki opis tych szeleczek na przykład z worda.</p> */}
            <div className='flex flex-row w-full justify-around p-3 text-xl'>
                {/* <p>{props.price}</p>
                <p className='text-gray-400 line-through'>299.99 zł</p> */}
            </div>
            <div>
              <button className="px-8 py-3 bg-[#4B89CA] text-white rounded-full my-2 text-xl hover:bg-[#FCDB4B] hover:text-black hover:cursor-pointer">Kup teraz!</button>
            </div>
          </div>
  )
}

export default FeaturedItem