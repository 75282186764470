import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductPresentationCard from "./ProductPresentationCard";

function ProductPresentation() {
    const { type, prodId } = useParams(); // Retrieve the 'id' parameter from the URL
    const [products, setProducts] = useState([]);
    const [wantedProduct, setWantedProduct] = useState(null);

    const getData = () => {
        fetch("../../products.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((myjson) => {
            console.log(prodId);
            // console.log(type);
            setProducts(myjson);
            // console.log(products);
          });
      };
    
      useEffect(() => {
        getData();
      }, []); // Fetch data when the component mounts

      useEffect(() => {
        if (products.length > 0) {
          const product = products.find((product) => product.id === prodId);
          setWantedProduct(product); // Update foundProduct when products have been fetched
        }
      }, [products, type]); // Execute when 'products' or 'type' changes
  return (
    <div>
        {products &&
              products.length > 0 &&
              products.map((product) => {
                if (product.id === prodId) {
                  return (
                    <ProductPresentationCard
                      key={product.id}
                      title={product.name}
                      image={product.image}
                      description={product.description}
                      price={product.price}
                      prodIds={product.prodIds}
                      type={product.type}
                    />
                  );
                } else {
                  return null;
                }
              })}
    </div>
  )
}

export default ProductPresentation