import React from 'react'

import Navigation from "../components//mainPage/Navigation";
import Footer from "../components/mainPage/Footer";
import Map from '../components/contactPage/Map';
import ContactContainer from '../components/contactPage/ContactContainer';

function ContactPage() {
  return (
    <>
    <Navigation />
          <Map />
          <ContactContainer />
          <Footer />
    </>
  );
}

export default ContactPage