import React from "react";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import logo from "../../assets/mittLogoBetter.png";

import "./Footer.css";

function Footer() {
  return (
    <div className="w-full flex bg-gray-300 pb-2 border-t-[1px]">
      <div className="w-[100%] lg:w-[90%] md:w-[80%] mx-auto flex flex-col">
        <div className="w-full flex mx-auto mt-2 flex-col xl:flex-row">
          {/* info */}
          <div className="w-1/2 text-center flex flex-col xl:flex-row py-10  mx-auto">
            <div>
              <h2 className="text-xl py-2 font-bold">LOKALIZACJA</h2>
              <p className="text-lg">Widzewska 14</p>
              <p className="text-lg">Łódź, Polska</p>
            </div>
            <div className="w-[120px] md:w-[255px] mx-auto py-2 flex align-middle">
              <img src={logo} alt="" />
            </div>
            <div className="mt-4">
              <h2 className="text-xl py-2 font-bold">KONTAKT</h2>
              <p className="text-lg">
                <a href="tel:604086394">604 086 394</a>
              </p>
              <p className="text-lg">
                <a href="mailto:biuro@md.com.pl">
                  biuro@md.com.pl
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* credits */}
        <div className="mx-auto">
          <h2 className="">
            Copyright JSTAgency 2023 All Rights Reserved.
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Footer;
