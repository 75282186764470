import React from "react";

import "./AboutUs.css";

function AboutUs() {
  return (
    <div className="w-screen flex mt-10 pb-10 flex-col">
      <div className="w-[100%] lg:w-[90%] md:w-[80%] mx-auto flex justify-between xl:flex-row flex-col">
        {/* left */}
        <div className="xl:w-1/2 p-3 w-full tracking-widest">
          <p className="text-black py-2 text-lg montserrat-font flex items-center">
            WITAMY W{" "}
            <p className="text-[#4B89CA] py-2 text-lg montserrat-font p-1 font-bold">
              MITTELMANN!
            </p>
          </p>
          <h1 className="font-light text-5xl py-4">SPRAWDŹ KIM JESTEŚMY!</h1>
          <p
            className="tracking-normal text-xl py-3 text-justify first-line:tracking-widest
                      first-letter:text-7xl first-letter:font-bold
                      first-letter:mr-3 first-letter:float-left"
          >
            Mittelmann ma wieloletnie doświadczenie w produkcji urządzeń
            ratowniczo-ewakuacyjnych oraz sprzętu do pracy w zawisie i
            chroniącego przed upadkiem z wysokości. Sprzęt firmy Mittelmann jest
            powszechnie stosowany w wielu krajach Europy i świata m.in. w
            budownictwie, energetyce, przemyśle. Stosują go również od lat z
            powodzeniem służby ratownicze oraz wojsko. Referencje firm,
            organizacji i instytucji państwowych są motywacją do wprowadzenia
            specjalistycznego sprzętu firmy Mittelmann na polski rynek.
          </p>

          <div className="flex w-full justify-between border-b-[1px] border-slate-200 xl:flex-row flex-col"></div>

          <p className="tracking-normal text-xl py-3 text-justify border-b-[1px] border-slate-200">
            Urządzenia i sprzęt produkowany przez firmę Mittelmann i oferowany
            przez Przedsiębiorstwo MD, posiadają atesty i niezbędne certyfikaty
            potwierdzające spełnianie wymagań wyznaczonych przez Państwową
            Inspekcję Pracy dla prac wykonywanych na wysokości, w szczególności
            prac w podwieszeniu, oraz dla prac w szybach i kanałach ściekowych.
          </p>
        <p className="tracking-normal text-xl py-3 text-justify border-b-[1px] border-slate-200">
          Specjalistyczny sprzęt firmy Mittelmann spełnia wymogi polskich norm w
          zakresie środków ochrony indywidualnej chroniących przed upadkiem z
          wysokości. Wygodny w użyciu jak sprzęt alpinistyczny, ale spełniający
          przepisy w zakresie BHP i wymagania polskich norm. Taki właśnie jest
          sprzęt firmy Mittelmann.
        </p>
        </div>

        {/* right */}
        <div className="xl:w-[45%] w-full flex p-3">
          <div className="w-[100%] h-full my-auto plan-photo"></div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
