import React, { useState } from "react";
import { Link as OuterLink } from "react-router-dom";

import logo from "../../assets/mittLogoBetter.png";

function Navigation() {
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  return (
    // whole nav
    <div className="w-screen flex bg-[#4B89CA] border-b">
      {/* container for logo, menu and button */}
      <div className="w-[95%] lg:w-[90%] md:w-[80%] mx-auto flex justify-between py-3">
        {/* logo */}
        <OuterLink to="/">
        <div className="w-[185px] md:w-[185px] py-2 flex align-middle">
          <img src={logo} alt="" />
        </div>
        </OuterLink>
        {/* menu */}
        <div className="w-[40%] mr-[4%] my-auto text-white">
          <ul className="hidden xl:flex justify-around py-4 text-xl">
            <li className="hover:cursor-pointer hover-underline-animation font-semibold group transition duration-300">
              <OuterLink to="/">
                Home
                <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-[#E8E8E8]"></span>
              </OuterLink>
            </li>
            <li className="hover:cursor-pointer hover-underline-animation font-semibold group transition duration-300">
              <OuterLink to="/produkty">
                Produkty
                <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-[#E8E8E8]"></span>
              </OuterLink>
            </li>
            {/* <li className="hover:cursor-pointer hover-underline-animation font-semibold group transition duration-300">
              <OuterLink to="/przeglady">
                Przeglądy
                <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-[#E8E8E8]"></span>
              </OuterLink>
            </li> */}
            <li className="hover:cursor-pointer hover-underline-animation font-semibold group transition duration-300">
              <OuterLink to="/o-nas">
                O nas
                <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-[#E8E8E8]"></span>
              </OuterLink>
            </li>
            <li className="hover:cursor-pointer hover-underline-animation font-semibold group transition duration-300">
              <OuterLink to="/kontakt">
                Kontakt
                <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-[#E8E8E8]"></span>
              </OuterLink>
            </li>
          </ul>
        </div>

        {/* burger menu buttons */}
        <div className="block xl:hidden mr-4 my-auto" onClick={handleClick}>
          {!nav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#FFFFFF"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#FFFFFF"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </div>
      </div>
      {/* hidden nav */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute bg-zinc-100 w-full px-8 mt-24 burgermenu pb-8"
        }
      >
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <OuterLink to="/">
            Home
          </OuterLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <OuterLink to="/produkty">
            Produkty
          </OuterLink>
        </li>
        {/* <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <OuterLink to="/szkolenia">
            Przeglądy
          </OuterLink>
        </li> */}
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <OuterLink to="/o-nas">
            O nas
          </OuterLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <OuterLink to="/kontakt">
            Kontakt
          </OuterLink>
        </li>
      </ul>
    </div>
  );
}

export default Navigation;
