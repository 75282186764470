import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as OuterLink } from "react-router-dom";
import "./Hero.css";

function Hero() {
  return (
    <div className="w-full h-fit flex py-10  herobg">
      <div className="text-white h-fit w-[90%] lg:w-[40%] my-auto mx-auto lg:ml-[10%]">
        <h1 className="text-4xl lg:text-5xl xl:text-6xl tracking-wider p-3">
          WZNOSIMY BEZPIECZEŃSTWO NA NOWE WYŻYNY!
        </h1>
        <p className="text-lg tracking-wider font-light p-3 shadow-text">
        Jesteśmy liderem w dziedzinie sprzętu bezpieczeństwa wysokościowego, dostarczając innowacyjne rozwiązania, które podnoszą standardy ochrony pracowników na każdej wysokości. Nasze produkty zapewniają nie tylko bezpieczeństwo, ale również komfort i pewność podczas pracy w ekstremalnych warunkach. Dzięki naszym rozwiązaniom, praca na wysokości staje się nie tylko bezpieczna, ale także efektywna i komfortowa.
        </p>
        <div className="flex justify-between xl:w-[30%] mt-5 p-3">
          <ScrollLink to="offer" smooth={true} duration={500}>
            <button
              className="border border-[#4B89CA] p-3 px-10 bg-[#4B89CA] text-white  shadow-black
        font-bold flex text-lg button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105"
            >
              Oferta
            </button>
          </ScrollLink>
          <OuterLink to="/kontakt">
            <button
              className="border border-[#4B89CA] p-3 px-10 ml-5 bg-[#f5fbf9] text-black shadow-black
        font-bold hover:text-black hover:bg-[#f5fbf9] flex text-lg button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105"
            >
              Kontakt
            </button>
          </OuterLink>
        </div>
      </div>
    </div>
  );
}

export default Hero;
