import React, {useState, useEffect} from 'react';
import { Link as OuterLink } from "react-router-dom";


import szelki from "../../assets/szelki.png";
import FeaturedItem from './FeaturedItem';

function Featured() {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {
    // Fetch the JSON data from your API or file
    fetch('/products.json')
      .then((response) => response.json())
      .then((jsonData) => {
        // Shuffle the JSON data randomly
        const shuffledData = [...jsonData].sort(() => Math.random() - 0.5);

        // Select the first four items as featured products
        const selectedFeaturedProducts = shuffledData.slice(0, 4);

        setFeaturedProducts(selectedFeaturedProducts);
      });
  }, []);
  return (
    <div className="w-full h-fit flex bg-[#DEDEDE] pb-10">
      <div className="w-full h-fit flex flex-col mx-auto text-center">
        <div className="w-fit mx-auto p-7">
          <h1 className="text-5xl p-2">WYRÓŻNIONE PRODUKTY</h1>
          <span class="block max-w-[15%] h-0.5 bg-[#4B89CA] mx-auto"></span>
        </div>
        <div className="w-[80%] h-fit flex flex-wrap flex-row mx-auto justify-between mt-5">
        {featuredProducts &&
                  featuredProducts.length > 0 &&
                  featuredProducts.map((product) => {
                    return (
                      <OuterLink to={`/produkty/${product.type}/${product.id}`}>
                      <FeaturedItem 
                      title = {product.name}
                      price = {product.price}
                      image = {product.image}/>
                      </OuterLink>
                    );
                  })}
        </div>
      </div>
    </div>
  )
}

export default Featured