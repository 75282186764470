import React from 'react'
import Navigation from '../components/mainPage/Navigation'
import AboutUs from '../components/aboutUs/AboutUs'
import Footer from '../components/mainPage/Footer'

function AboutUsPage() {
  return (
    <div>
        <Navigation />
        <AboutUs />
        <Footer />
    </div>
  )
}

export default AboutUsPage