import "./App.css";
import MainPage from "./pages/MainPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProductsPage from "./pages/ProductsPage";
import OneProductPage from "./pages/OneProductPage";
import AllProductsPage from "./components/productsPage/AllProductsPage";
import ContactPage from "./pages/ContactPage";
import AboutUsPage from "./pages/AboutUsPage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<MainPage />}></Route>
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/home" element={<MainPage />}></Route>
          <Route path="/produkty" element={<AllProductsPage />}></Route>
          <Route path="/produkty/:type" element={<ProductsPage />}></Route>
          <Route path="/produkty/:type/:prodId" element={<OneProductPage />}></Route>
          <Route path="/kontakt" element={<ContactPage />}></Route>
          <Route path="/o-nas" element={<AboutUsPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
