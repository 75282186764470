import React from 'react'
import Navigation from '../components/mainPage/Navigation'
import Hero from '../components/mainPage/Hero'
import Offer from '../components/mainPage/Offer'
import Advantages from '../components/mainPage/Advantages'
import Featured from '../components/mainPage/Featured'
import Footer from '../components/mainPage/Footer'

function MainPage() {
  return (
    <div>
      <Navigation />
      <Hero />
      <Offer />
      <Advantages />
      <Featured />
      <Footer />
    </div>
  )
}

export default MainPage