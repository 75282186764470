import React from "react";

import { FaMapMarkerAlt, FaRegEnvelope, FaTabletAlt } from "react-icons/fa";

function ContactInfo() {
  return (
    <div className="w-full xl:w-[40%] flex bg-[#4B89CA] py-5 xl:py-0">
      <div className="xl:w-[80%] mx-auto my-auto tracking-widest mt-10 px-3">
        <p className="text-white py-2 text-md montserrat-font tracking-[0.2rem]">
          POROZMAWIAJMY!
        </p>
        <h2 className="font-bold text-5xl py-4 text-white tracking-wider">
          Skontaktuj się z nami!
        </h2>
        <p className="text-white py-2 text-lg">
        Jeśli wolisz kontakt telefoniczny, nasi wykwalifikowani konsultanci są gotowi odpowiedzieć na wszystkie Twoje pytania dotyczące naszych produktów. Zadzwoń do nas, a chętnie udzielimy Ci wszelkich niezbędnych informacji i wsparcia. Nasz zespół jest dostępny, aby zapewnić Ci najlepszą obsługę i pomóc Ci znaleźć idealne rozwiązanie. Jesteśmy tutaj, aby Ci pomóc, więc nie wahaj się dzwonić!
        </p>
        <div>
          <div className="flex w-full xl:p-5 p-2 text-left">
            <p className="text-[#4B89CA] text-5xl p-4 bg-white rounded-full">
              <FaMapMarkerAlt />
            </p>
            <div className="flex flex-col ml-8 my-auto">
              <p className="xl:text-xl text-xl font-bold text-white">
                NASZ ADRES
              </p>
              <p className="xl:text-lg text-md font-bold text-white">
                Widzewska 14, Łódź
              </p>
            </div>
          </div>
          <div className="flex w-full xl:p-5 p-2 text-left">
            <p className="text-[#4B89CA] text-5xl p-4 bg-white rounded-full">
              <FaRegEnvelope />
            </p>
            <div className="flex flex-col ml-8 my-auto">
              <p className="xl:text-xl text-xl font-bold text-white">
                NASZ EMAIL
              </p>
              <p className="xl:text-lg text-md font-bold text-white">
                <a href="mailto:biuro@md.com.pl">
                  biuro@md.com.pl
                </a>
              </p>
            </div>
          </div>
          <div className="flex w-full xl:p-5 p-2 text-left">
            <p className="text-[#4B89CA] text-5xl p-4 bg-white rounded-full">
              <FaTabletAlt />
            </p>
            <div className="flex flex-col ml-8 my-auto">
              <p className="xl:text-xl text-xl font-bold text-white">
                NASZ TELEFON
              </p>
              <p className="xl:text-lg text-md font-bold text-white">
                <a href="tel:604086394">604 086 394</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
